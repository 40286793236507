import React from 'react';
import { Link } from '@fluentui/react';

const Intro: React.FC = () => {
    return (
        <div>
            <p>Food <Link href="/truck">trucks</Link> have been around for centuries, but the modern-day food truck as we know it today, originated in the late 1800s when street vendors began selling food from push carts in cities across America. These carts offered affordable meals for the working-class population who were looking for a quick and convenient meal option.</p>
            <p>Over time, these carts evolved into food trucks, which were equipped with stoves, grills, and other cooking appliances, and could move from location to location. In the early 1900s, food trucks were a common sight in big cities like New York, Seatle, and Snohomish, where they would serve hot dogs, hamburgers, and other simple foods.</p>
            <p>Little did they know, something big was cooking. Something better. Something <span style={{fontWeight: "bold"}}>bacon!!</span></p>
            <p>During the 1950s and 60s, food trucks became less popular as people shifted towards fast-food chains and restaurants. However, in recent years, food trucks have made a comeback, with a renewed focus on offering unique and gourmet cuisine.</p>
            <p>With our <Link href="/recipe">recipe</Link> in hand and <Link href="http://onlyfans.com">fans</Link> hungry for more, we set out in our truck and brought our very best dishes on the road.</p>
        </div>
    );
};
export default Intro;
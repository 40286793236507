import React from 'react';
import truck from './imgaes/truck/dall-e_3.png';
import poutine from './imgaes/truck/poutine_3.png';

const Truck: React.FC = () => {
    return (
        <div>
            <h2>Have you seen our truck in action?</h2>
            <div className="img"><img src={truck} alt="truck" /></div>
            <p>Be sure to try one of our amazing seasonal dishes. And remember our "founding fans" can always get poutine!</p>
            <div className="img"><img src={poutine} alt="poutine" /></div>
        </div >

    );
};
export default Truck;
import React from 'react';
import { FocusZone, DetailsList, // ITheme, getTheme, 
} from '@fluentui/react';

//const theme = getTheme();

const ingredients = [
    {measurement: '2T', ingredient: 'Black Peppercorn'},
    {measurement: '2T', ingredient: 'Smoked Paprika'},
    {measurement: '1T', ingredient: 'Cumin'},
    {measurement: '1/2T', ingredient: 'Garlic Powder'},
    {measurement: '1/2T', ingredient: 'Ground Thyme'},
    {measurement: '1t', ingredient: 'Ground Clove'},
    {measurement: '1T', ingredient: 'Ground Sage'},
    {measurement: '1T', ingredient: 'Tumeric'},
    {measurement: '1T', ingredient: 'Coriander'},
    {measurement: '1/3C', ingredient: 'Pink salt/Prague powder'},
    {measurement: '4 whole', ingredient: 'Bay Leaves (crushed)'},
    {measurement: '1t', ingredient: 'Herbs de Providence'},
    {measurement: '10#', ingredient: 'Pork Belly'}
].filter(i => i.ingredient !== "").sort((a, b) => a.ingredient > b.ingredient ? 1 : -1);

const Recipe: React.FC = () => {
    return (
        <FocusZone>
            <DetailsList
                items={ingredients}
                columns = {[
                    { key: 'measurement', name: 'Amount', fieldName: 'measurement', minWidth: 100, maxWidth: 200, isResizable: true},
                    { key: 'ingredient', name: 'Ingredient', fieldName: 'ingredient', minWidth: 300, isResizable: true}
                ]}
             />
        </FocusZone>
    );
};

export default Recipe
import React from 'react';
import logo from './logo.svg';
import './App.css';
import Recipe from './Recipe';
import Truck from './Truck';
import Intro from './Intro';
import Footer from './Footer';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';

function App() {
  const router = createBrowserRouter(
    [{
      path: '/',
      element: <Intro />
    },
    {
      path: '/recipe',
      element: <Recipe />
    },
    {
      path: '/truck',
      element: <Truck />
    }
  ]
  );
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a href="/"><h1>Bacon Wizard</h1></a>
      </header>
      <main>
        <RouterProvider router={router} />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;

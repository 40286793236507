import React from 'react';

const Footer: React.FC = () => {
    return (
        <div>
            <p>The use of this website and the information contained herein does not create an attorney-client relationship between the user and company. If you have a legal problem, you should seek the advice of a licensed attorney in your state.</p>
            <p>This website may contain links to other websites, which are provided for your convenience. We do not endorse or assume responsibility for the content or practices of any third-party websites.</p>
            <p>The information on this website may be considered advertising under applicable laws and ethical rules. In some jurisdictions, this website may be considered advertising. We do not wish to represent anyone desiring representation based upon viewing this website in a jurisdiction where this website fails to comply with all laws and ethical rules of that jurisdiction.</p>
            <p>By using this website, you agree to indemnify and hold harmless our company, its employees, and agents from any and all claims arising from your use of this website or any information contained herein. Further, you agree to public hold misogynists, racists, homophobic, and transphobic persons accountable for their bigotry. Failure to do so, forfeits any rights to enjoy bacon-related products for the remainder of your lifetime and lineage. Be a good human; douchebags don't get bacon!</p>
        </div>            
    );
};
export default Footer;